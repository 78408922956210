.header-color{
  background: #52c234; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #52c234, #061700); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #276416, #061700); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid{
  background: #215f00; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #215f00, #e4e4d9); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #215f00, #e4e4d9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-img{
  height: 250px;
  padding-top: 5em;
}

.banner-text{
  background-color: black;
  opacity: 0.8;
  width: 75%;
  height: auto;
  margin: auto;
  border-radius: 10px;
}

.mobileBanner .bannerHeader{
 font-size: 25px;
}

.mobileBanner .bannertechnologies{
  font-size: 15px;
  padding: 5px;
}

.mobileBanner .social-links i{
  font-size: 2em;
 }


@media screen and (max-width: 400px){
  .avatar-img{
    height: 10vh;
  }
  
  
  .bannerHeader{
    font-size: 30px !important; 
  }
  
  .bannertechnologies{
    font-size: 15px !important;
  }

  .social-links i{
    font-size: 1em !important;
  }

  .aboutMeText{
    font-size: 10px !important;
  }

  .listItemStyles{
    font-size: 10px !important;
    font-family: 'Anton';
  }

  .contactClass{
    font-size: 20px !important;
  }

  .contact-grid-class{
    height: 100%;
  }

  .contactMeAvatar{
    height: 100px !important;
  }
  
  .media{
    height: 150px;
    width: 150px;
  }
}

.bannerHeader{
  margin-top: 30px;
  font-size: 50px;
  padding-top: 20px;
  font-weight: bold;
  color: white;
}

.banner-text hr{
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.bannertechnologies{
  color: white;
  font-size: 20px;
  padding: 1em;
}

.footerClass{
  background: #52c234; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #52c234, #061700); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #276416, #061700); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: fixed;
  bottom: 0;
  width: 100% !important;
  height: 20px;
  text-align: center;
  justify-content: center;
}

.desktopFooterPadding{
  padding: 20px 40px !important;
}
.phoneFooterClass{
  padding: 10px 10px !important;
}

.footerCopyrightClass p{
  font-size: 10px;
  width: 250px;
  text-align: left;
}

.footerLinks{
  position: fixed;
  right: 0;
  margin-right: 20px;
  font-size: 30px;
}

.phoneIcon i {
  font-size: 20px;
}

.social-links{
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  color: white;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.project-grid{
  display: block;
  height: 500px;
  width: 600px;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
}

.project-button{
  height: 30px;
  line-height: 0.5;
  background-color: #4d823f;
  opacity: 0.9;
  color: beige;
  border-radius: 8px;
  padding: 5px;
}

/* Contact Page CSS */
.contact-body{
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: auto;
  padding-top: 2em;
}

.contact-grid{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

/* .demo-big-content{
  height: 100vh;
  width: 100vh;
} */

.contactMeAvatar{
  height: 250px;
}

.myName{
  font-family: 'Anton', sans-serif;
}

.myIntroClass{
  width: 75%;
  margin: auto;
  padding-top: 2em;
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contactClass{
  font-family: 'Anton', sans-serif;
  font-size: 45px;
  margin-top: 24px;
}

.phoneContactClass .contactClass{
  font-size: 20px !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

.phoneContactClass .underline{
  margin: 0px;
}

.phoneContactClass .mdl-list{
  margin: 0px 20px;
  text-align: center;
}

.phoneContactClass .iconFont{
  font-size: 10px;
}

.phoneContactClass .listItemStyles{
  font-size: 10px;
}

.phoneFooterClass

.underline{
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contactList{
  margin: auto;
  width: 50%;
  text-align: center;
  justify-content: center;
}

.listItemStyles{
  font-size: 25px;
  padding-left: 5em;
  font-family: 'Anton';
}

.iconFont{
  font-size: 25px;
  padding-right: 1em;
  font-weight: bold;
}

/* Resume Page CSS */
.resume-left-col{
  text-align: center;
}

.phoneAvatar{
  height: 150px !important;
}

.resume-right-col{
  background: rgb(29, 28, 28);
  color: right;
}

.resumeName{
  padding-top: 2em;
  color: grey;
}

.colorUnderline{
  border-top: 3px solid rgb(25, 100, 35);
}

.resumeBlocks{
  padding: 2em;
  color: white;
}

/* Experience classes */
.experience-img{
  height: 50px;
  width: 100px;
}

.companyImage{
  width: 100%;
}

.companyTitle{
  margin-top:'20px';
  width: '85%';
  float: 'right';
}

/* About Me Page */
.aboutmeImage{
  margin: auto;
  padding-top: 5em;
  justify-content: center;
}

.aboutMeDetails{
  margin: auto;
  padding-top: 5em;
  font-family: 'Oxygen', sans-serif;
}

.aboutMeText{
  font-size: 20px;
}

.Portfolio{

  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.mediaHome{
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.mediaHome p{
  font-size: 24px;
  font-weight: bold;
}

.mediaHome img{
  height: 500px;
  width: 425px;
}

.phoneMediaHome{
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.phoneMediaHome p{
  font-size: 12px;
  font-weight: bold;
}

.phoneMediaHome img{
  height: 250px;
  width: 200px;
}

.gallery{
  display: flex;
  flex-direction: row;
}

.card {
  border-radius: 5px;
  margin: 0px 25px;
  box-shadow: 20px 20px 20px black;
  display: flex;
  justify-content: center;
}

.phoneMedia{
  height: 500px;
  width: 280px;
}

.media {

  /* @media screen and (min-width: 1024px) { */
    height: 500px;
    width: 425px;
  /* `} */
  
}
